// Front-end paths

$url_static: "/static/"
$url_images: "/static/images/"
$url_fav: "/static/fav/"
$url_svg: "/static/svg/"
$url_fonts: "/static/fonts/"
$url_css: "/static/css/"
$url_js: "/static/js/"
$url_sinner: "/static/sinner/"

$url_media: "/media/"
$url_sass: "../../sass/"

// helpers
// ========================================================

@import '_src/sass/helpers/reset'
@import '_src/sass/helpers/mixins_breakpoint'
@import '_src/sass/helpers/flexbox'
@import '_src/sass/helpers/mixins-sa'
@import '_src/sass/helpers/mixins-sc'

// project core
// ========================================================

@import '_src/sass/vars'
@import '_src/sass/layouts/core/layout'
// @import '_src/sass/page/cms'
@import '_src/sass/typo'
@import '_src/sass/body'
@import '_src/sass/wcag'

// project plugins
// ========================================================

// @import '_src/sass/plugins/lazyloading'
@import '_src/sass/plugins/prettyPhoto'

// elements
// ========================================================

@import '_src/sass/elements/buttons'
@import '_src/sass/elements/inputs'
@import '_src/sass/elements/lists'
@import '_src/sass/elements/gremlins'

// page
// ========================================================

@import '_src/sass/page/header'
@import '_src/sass/page/footer'
@import '_src/sass/page/sections'
@import '_src/sass/page/home'

// page modules
// ========================================================

@import '_src/sass/modules/art'
@import '_src/sass/modules/nav-social'
@import '_src/sass/modules/nav-top'
@import '_src/sass/modules/gallery-cms'
@import '_src/sass/modules/search'
@import '_src/sass/modules/catalog'
@import '_src/sass/modules/blog'
@import '_src/sass/modules/video'
@import '_src/sass/backend/protected'

// @import '_src/sass/modules/mapgoogle'
// @import '_src/sass/modules/fs-gallery'
// @import '_src/sass/modules/fs-tooltip'
// @import '_src/sass/modules/page-banner'

// custom for project (fast build ;)
// ========================================================

@import '_src/sass/project-custom'
@import '_src/sass/helpers/margins'
// @import '_src/sass/layouts/uniquespirits/layout'

// @import '_src/sass/plugins/flexslider'

// hentai
// ========================================================

@import '_src/sass/animations/animations--basic'
@import '_src/sass/animations/animations--project'
